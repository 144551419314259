/* eslint-disable jsx-quotes */
import { CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';

export default function BannerApp(props) {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <>
      {showBanner && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            bottom: -1,
            left: 0,
            right: 0,
            textAlign: 'center',
            backgroundColor: '#000',
            borderTop: '1px solid #d9d9d9',
            zIndex: 1000,
          }}
        >
          <a href="https://hanagold.app.link">
            <img
              src="/assets/img/app-home/img_banner_app.jpg"
              alt="Gold Trading Banner"
            />
          </a>
          <button
            style={{
              position: 'absolute',
              top: '20px',
              left: '10px',
              width: '40px',
              height: '40px',
              backgroundColor: 'rgb(227 227 227 / 31%)',
              border: 'none',
              borderRadius: '100%',
              color: '#fff',
              cursor: 'pointer',
            }}
            onClick={() => setShowBanner(false)}
          >
            <CloseOutlined />
          </button>
        </div>
      )}
    </>
  );
}

/* eslint-disable jsx-quotes */
import Link from 'next/link';
import getConfig from 'next/config';
import { getSocial } from '@/redux/actions/layout';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import useTrans from '@/hooks/useTrans';
import { CloseOutlined } from '@ant-design/icons';
import BannerApp from './BannerApp';

const Footer = props => {
  const trans = useTrans();
  const { isMobile } = props;
  const { social } = useSelector(state => {
    return {
      social: state.layout.social,
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  useEffect(() => {
    getSocial({}, dispatch);
  }, []);
  const obj_social = {};
  social.map(social => {
    obj_social[social.config_key] = social.config_value;
  });
  return (
    <>
      {isMobile && <BannerApp />}
      <footer className={!isMobile ? 'l-footer' : 'l-footer'}>
        <div className="l-footer__inner">
          <div className="l-footer__inner__row">
            <div className="l-footer__inner__row__block1">
              <ul className="l-footer__inner__row__block__link">
                <li>
                  <h2>HANAGOLD</h2>
                </li>
                <li>
                  <Link href="/gioi-thieu">
                    <a>
                      <div>{trans?.footer?.intro}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/#store-branch">
                    <a>
                      <div>{trans?.footer?.store}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/san-pham">
                    <a>
                      <div>{trans?.footer.product}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/tin-tuc-su-kien">
                    <a>
                      <div>{trans?.footer.news}</div>
                    </a>
                  </Link>
                </li>
              </ul>

              {/* <ul className="l-footer__inner__row__block__social">
              KẾT NỐI VỚI CHÚNG TÔI
              <li>
                <a target="_blank" href={obj_social['LINK_FB']?.url || '#'}>
                  <img
                    className="bct"
                    src="/assets/img/common/icn_facebook_01.svg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a target="_blank" href={obj_social['LINK_INS']?.url || '#'}>
                  <img
                    className="bct"
                    src="/assets/img/common/icn_instagram_01.svg"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={obj_social['LINK_YOUTUBE']?.url || '#'}
                >
                  <img
                    className="bct"
                    src="/assets/img/common/icn_youtube_01.svg"
                    alt=""
                  />
                </a>
              </li>
            </ul> */}
            </div>
            <div className="l-footer__inner__row__block2">
              <h2>{trans?.footer?.introduce}</h2>
              <ul className="l-footer__inner__row__block__link">
                <li>
                  <Link href="/huong-dan-mua-hang">
                    <a>
                      <div>{trans?.footer?.introduce_a}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/huong-dan-thanh-toan">
                    <a>
                      <div>{trans?.footer?.introduce_b}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/huong-dan-bao-quan-trang-suc">
                    <a>
                      <div>{trans?.footer?.introduce_c}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/huong-dan-cach-do-size-trang-suc">
                    <a>
                      <div>{trans?.footer?.introduce_d}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/huong-dan-giao-nhan-hang">
                    <a>
                      <div>{trans?.footer?.introduce_e}</div>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="l-footer__inner__row__block3">
              <h2>{trans.footer.policy}</h2>
              <ul className="l-footer__inner__row__block__link">
                <li>
                  <a
                    target="_blank"
                    href="https://check.hanagold.vn"
                    style={{ color: '#fff', fontWeight: '600' }}
                  >
                    {trans.footer.policy_a}
                  </a>
                </li>
                <li>
                  <a target="_blank" href="/cong-bo-tieu-chuan-co-so">
                    {trans.footer.policy_b}
                  </a>
                </li>
                <li>
                  <Link href="/quy-dinh-bao-hanh">
                    <a>
                      <div>{trans.footer.policy_c}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/security">
                    <a>
                      <div>{trans.footer.policy_d}</div>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/policy">
                    <a>
                      <div>{trans.footer.policy_e}</div>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="l-footer__inner__row__block4" id="contact">
              <h2>{trans.footer.contact}</h2>
              <ul className="l-footer__inner__row__block__link">
                <li>
                  <span>
                    <b style={{ color: '#fff' }}>{trans.footer.name}</b>
                  </span>
                </li>
                <li>
                  <span>
                    <b>HCM: </b>
                    {trans.footer.add}
                  </span>
                </li>
                <li>
                  <span>
                    <b>Email: </b>contact@hanagold.vn
                  </span>
                </li>
                <li>
                  <span>
                    <b>Hotline: </b>19002161
                  </span>
                </li>
                <li>
                  <span>
                    <b>MST:</b> {trans.footer.tax}
                  </span>
                </li>
                {/* <li>
                <a href="http://online.gov.vn/Home/WebDetails/87160">
                  <img
                    className="bct"
                    src="/assets/img/common/img_bct_01.png"
                    alt=""
                  />
                </a>
              </li> */}
              </ul>
            </div>
          </div>

          <div className="l-footer__seconds">
            <div>
              <div className="footer_seconds_left">
                <p>{trans.footer.touch}</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href={obj_social['LINK_FB']?.url || '#'}
                      rel="nofollow"
                    >
                      <img
                        className="bct"
                        src="/assets/img/social/fb_icon.svg"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={'https://www.tiktok.com/@hanagold.vn'}
                      rel="nofollow"
                    >
                      <img
                        className="bct"
                        src="/assets/img/social/tiktok_icon.svg"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://zalo.me/vanghanagold"
                      rel="nofollow"
                    >
                      <img
                        className="bct"
                        src="/assets/img/social/zalo_icon.svg"
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={obj_social['LINK_YOUTUBE']?.url || '#'}
                    >
                      <img
                        className="bct"
                        src="/assets/img/social/youtube_icon.svg"
                        alt=""
                      />
                    </a>
                  </li>
                  {/* <li>
                  <a href="mailto:contact@hanagold.vn">
                    <img
                      className="bct"
                      src="/assets/img/social/gmail_icon.svg"
                      alt=""
                    />
                  </a>
                </li> */}
                </ul>
              </div>
              <div className="footer_seconds_center">
                <p>{trans.footer.community}</p>
                <span>{trans.footer.community_a}</span>
                <a
                  href="https://zalo.me/g/kplgmu445"
                  target="_blank"
                  rel="nofollow"
                >
                  <img src="" alt="" />
                </a>
                <div className="ft_zl">
                  <a
                    href="https://zalo.me/g/kplgmu445"
                    target="_blank"
                    rel="nofollow"
                  >
                    <img
                      className="bct"
                      src="/assets/img/new_img/zl.svg"
                      alt=""
                    />
                    <p>{trans.footer.join}</p>
                  </a>
                </div>
              </div>
              <div className="footer_seconds_right">
                <a
                  href="http://online.gov.vn/Home/WebDetails/87160"
                  rel="nofollow"
                >
                  <img
                    className="bct"
                    src="/assets/img/common/img_bct_01.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
